<template>
  <div class="app-container" v-loading="loading">
    <el-card>
      <el-row style="margin-left: 5px">
        <el-col :span="7">
          <div class="card1-input_date my-date-input date">
            <span style="padding-left: 5px; color: #409eff; font-weight: 550; font-size: 13px">出场日期:</span>
            <el-date-picker
              v-model="dateRange"
              type="datetimerange"
              style="width: 75%"
              size="small"
              :clearable="false"
              :default-time="['00:00:00', '23:59:59']"
              value-format="yyyy-MM-dd HH:mm:ss"
              range-separator="-"
              @change="timeChanged"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </div>
        </el-col>
        <el-col :span="4">
          <el-select size="small" style="width: 90%" class="card1-input1" v-model="queryParams.workType" clearable placeholder="请选择">
            <el-option :label="'全部'" :value="null"> </el-option>
            <el-option :label="'白班'" :value="'DAY_WORK'"> </el-option>
            <el-option :label="'夜班'" :value="'NIGHT_WORK'"> </el-option>
            <template slot="prefix"><span class="input-prefix">班制：</span></template>
          </el-select>
        </el-col>
        <el-col :span="5">
          <el-select size="small" style="width: 95%" class="card1-input" v-model="queryParams.projectId" clearable placeholder="请选择">
            <el-option v-for="item in bossProList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
            <template slot="prefix"><span class="input-prefix">工地项目：</span></template>
          </el-select>
        </el-col>
        <el-col :span="5">
          <el-select size="small" style="width: 95%" class="card1-input" v-model="settleType" clearable placeholder="请选择">
            <el-option :label="'工地结算'" :value="'1'"> </el-option>
            <el-option :label="'渣土场结算'" :value="'2'"> </el-option>
            <template slot="prefix"><span class="input-prefix">结算类型：</span></template>
          </el-select>
        </el-col>
        <el-col :span="2">
          <el-button style="margin-top: 3px" @click="getData" type="primary" class="el-icon-search">查 询</el-button>
        </el-col>
      </el-row>
    </el-card>
    <el-card style="margin-top: 5px">
      <div slot="header">
        <el-button size="small" style="float: right" @click="exportDataEvent">下载表格<i class="el-icon-download el-icon--left"></i></el-button>
      </div>
      <el-row>
        <vxe-table ref="xTable1" border show-header-overflow show-overflow highlight-hover-row :data="tableData">
          <vxe-table-column align="center" type="seq" title="序号" width="60"></vxe-table-column>
          <vxe-table-column align="center" field="date" title="日期"></vxe-table-column>
          <vxe-table-column align="center" field="workType" title="班制"></vxe-table-column>
          <vxe-table-column align="center" field="projectName" title="工地项目"></vxe-table-column>
          <vxe-table-column align="center" field="ztcName" title="倒土方式" />
          <vxe-table-column align="center" v-if="settleType === '1'" field="workSettlementCount" title="工地已结算"></vxe-table-column>
          <vxe-table-column align="center" v-if="settleType === '1'" field="workUnSettleCount" title="工地未结算"> </vxe-table-column>
          <vxe-table-column align="center" v-if="settleType === '2'" field="muckSettlementCount" title="渣土场已结算"></vxe-table-column>
          <vxe-table-column align="center" v-if="settleType === '2'" field="muckUnSettleCount" title="渣土场未结算"> </vxe-table-column>
          <vxe-table-column align="center" field="count" title="合计"> </vxe-table-column>
        </vxe-table>
      </el-row>
    </el-card>
  </div>
</template>
<script>
import { projectList, settlementStt } from '@/api/stt'
import dayjs from 'dayjs'
const scale = [
  {
    dataKey: '总工单',
    min: 0
  }
]
export default {
  data() {
    return {
      settleType: '1',
      loading: false,
      dateRange: [],
      chartData: [], // 统计图数据
      scale,
      tableData: [],
      allAlign: null,
      bossProList: [],
      // 查询条件表单
      queryParams: {
        startTime: '',
        endTime: '',
        projectId: null,
        workType: ''
      }
    }
  },
  created() {
    let curentMon = new Date()
    let monFirstDay = dayjs(curentMon).startOf('month').format('YYYY-MM-DD HH:mm:ss')
    let monLastDay = dayjs(curentMon).endOf('month').format('YYYY-MM-DD HH:mm:ss')
    this.dateRange.push(monFirstDay)
    this.dateRange.push(monLastDay)
    // this.queryParams.startTime = monFirstDay
    // this.queryParams.endTime = monLastDay
    this.getProjectList()
    this.getData()
  },
  methods: {
    timeChanged(val) {
      console.log(val)
    },
    // 获取该老板所有的工地
    getProjectList() {
      projectList().then(res => {
        let arr = [{ id: null, name: '全部项目' }]
        res.data.forEach(item => {
          arr.push({ id: item.id, name: item.name })
        })
        this.bossProList = arr
      })
    },
    // 获取统计数据
    getData() {
      this.loading = true
      this.queryParams.startTime = this.dateRange[0]
      this.queryParams.endTime = this.dateRange[1]
      settlementStt(this.queryParams).then(res => {
        this.tableData = []
        res.data.forEach(item => {
          let obj = {
            count: item.count,
            date: '2022-06-14',
            muckSettlementCount: 0,
            muckUnSettleCount: parseInt(item.count) - parseInt(item.muckSettlementCount),
            workUnSettleCount: parseInt(item.count) - parseInt(item.workSettlementCount),
            projectName: '20',
            workSettlementCount: 0,
            workType: '白班',
            ztcId: '-1',
            ztcName: '自倒'
          }
          this.tableData.push(obj)
        })
        this.loading = false
      })
    },
    // 导出
    exportDataEvent() {
      this.$refs.xTable1.exportData({ filename: this.radio1, type: 'csv' })
    }
  }
}
</script>
<style rel="stylesheet/scss" lang="scss" scope>
.date .el-input__inner {
  border: none;
}
.my-date-input {
  border: 1px solid #dcdfe6;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  width: 90%;
  height: 45px;
  line-height: 40px;
}
.date .el-date-editor {
  margin-top: -5px !important;
}
.card1-input .el-input__prefix {
  left: 5px !important;
  padding-top: 12px !important;
}
.el-card__header {
  border-bottom: none;
}
.card1-input .el-input--prefix .el-input__inner {
  padding-left: 80px;
}
.card1-input1 .el-input__prefix {
  left: 5px !important;
  padding-top: 12px !important;
}
.card1-input1 .el-input--prefix .el-input__inner {
  padding-left: 60px;
}
.card1-input_date .input-prefix {
  font-style: normal;
  font-weight: 650;
  color: #409eff;
}
.card1-input .input-prefix {
  font-style: normal;
  font-weight: 650;
  color: #409eff;
}
.card1-input1 .input-prefix {
  font-style: normal;
  font-weight: 650;
  color: #409eff;
}
.card1-input1 .el-input__inner {
  height: 45px;
  line-height: 40px;
}
.card1-input .el-input__inner {
  height: 45px;
  line-height: 40px;
}
.el-date-editor .el-range-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  outline: none;
  display: inline-block;
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
  text-align: center;
  font-size: 14px;
  color: #606266;
}
</style>
